<template>
  <b-modal size="md" scrollable :id="'modal-owners' + contract.id" :title="'Propietarios del contrato '+contract.code" ok-only hide-footer >
    <b-card header-tag="header">
      <template #header>
        <h4 class="mb-0">Propietarios actuales</h4>
      </template>
      <div v-if="contract.owners.length > 0">
        <span v-for="owner in owners" :key="owner.ownername">
          <hr>
          <h5>{{owner.ownername}}</h5>
          <label>{{owner.relation ? owner.relationName : 'Sin relación'}} - {{owner.percentage}}%</label>
        </span>
      </div>
    </b-card>
  </b-modal>
</template>

<script>

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
    owners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'ownername', label: 'Propietario' },
        { key: 'percentage', label: 'Porcentaje' },
        { key: 'relation', label: 'Tipo de Propietario' },
      ],
    }
  },
  computed: {},
  methods: {},
};
</script>
