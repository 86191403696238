<template>
	<b-overlay :show="isSavingContract" rounded class="pl-2 pr-2">
		<ValidationObserver ref="searchOwner" v-slot="{ invalid }">
			<b-form @submit.prevent="getOwners">
				<b-row class="mt-1 border rounded p-1" v-if="!isSearchingOwners">
					<b-col md="12">
						<h4>Buscar propietario para el contrato </h4>
					</b-col>
					<!-- Resort -->
					<b-col md="3">
						<ValidationProvider name="Hotel" rules="required">
							<b-form-group label="Resort" slot-scope="{ valid, errors }">
								<b-form-select
									:state="errors[0] ? false : valid ? true : null"
									v-model="newContract.hotel"
								>
									<option :value="null">Seleccione Resort</option>
									<option
										v-for="hotel in hotels"
										:key="hotel.id"
										:value="hotel.id"
									>
										{{ hotel.name }}
									</option>
								</b-form-select>
								<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</b-col>
					<!-- Vivienda -->
					<b-col md="3">
						<ValidationProvider rules="required" name="Vivienda">
							<b-form-group label="Vivienda" slot-scope="{ valid, errors }">
								<b-form-input
									class="form-control"
									type="text"
									v-model="newContract.numberHouse"
									@keypress="onlyNumber"
									@keyup.enter="getOwners"
									:state="errors[0] ? false : valid ? true : null"
								>
								</b-form-input>
								<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col class="mt-2">
						<b-button
							v-if="newContract.nameHouse !=='' "
							@click="resetHouseSection"
							variant="warning"
							class="mr-2"
							:disabled="newContract.nameHouse ==='' "
						> Reset </b-button>
						<b-button
							type="submit"
							variant="primary"
							:disabled="invalid"
						> Buscar propietarios </b-button>
					</b-col>
					<b-col md="12">
						<b-alert variant="warning" :show="true" class="mb-0" v-if="(newContract.owners.length == 0)">
							<div class="alert-body">Para obtener un propietario, debe realizar una búsqueda indicando el resort y el número de vivienda deseado.<br>Sin un propietario no podrá completar los datos del contrato.</div>
						</b-alert>
						<b-alert variant="warning" :show="true" class="mb-0 mt-1" v-if="(showRedirectLabel)">
							<div class="alert-body">La vivienda selecionada cuenta con un contrato ({{ contractToEdit }}) sin fecha de término. Para agregarla, por favor <b-link @click="goEditContract">edítelo aquí</b-link> .
							</div>
						</b-alert>
					</b-col>
				</b-row>

			</b-form>
		</ValidationObserver>
		<div class="text-center mt-2" v-if="isSearchingOwners">
			<b-spinner style="width: 3rem; height: 3rem;" /><br>
			<strong>Buscando propietario</strong>
		</div>
		 <ValidationObserver ref="createContract" v-slot="{ invalid }" v-if="(newContract.owners.length > 0)">				
			<b-form @submit.prevent="saveContract">
				<b-row class="mt-1 border rounded p-1" >
					<b-col md="12">
						<h4 class="mb-1">
							<span v-if="newContract.nameHouse !==''">{{ newContract.nameHouse }}</span>
						</h4>
						<b-card-sub-title class="mb-2">Agregar datos a contrato</b-card-sub-title>
					</b-col>
					<b-col md="3">
						<ValidationProvider rules="required" name="Propietario">
							<b-form-group label="Propietario*" slot-scope="{ valid, errors }">
								<b-form-select
									:state="errors[0] ? false : valid ? true : newContract.ownerSelected != '' ? true : false"
									v-model="newContract.ownerSelected"
									@input="verifyPreviousContract"
								>
									<option value="">Elija un propietario</option>
									<option
										v-for="owner in newContract.owners"
										:key="owner.idOwner"
										:value="owner.idOwner"
									>
										{{ owner.ownerName.trim() }} {{ owner.ownerLastname.trim() }}
									</option>
								</b-form-select>
								<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="3">
						<ValidationProvider rules="required" name="Tipo de Membresía">
						<b-form-group label="Tipo Membresía*" slot-scope="{ valid, errors }">
							<b-form-select
							:state="errors[0] ? false : valid ? true : newContract.selectedeMembership != '' ? true : false"
							v-model="newContract.selectedeMembership"
							@change="setBenefitsForMembershipSelected"
							>
							<option selected value="">Seleccione una Membresía</option>
							<option v-for="member in memberships" :key="member.id" :value="member.id">
								{{ member.code }}
							</option>
							</b-form-select>
							<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="3">
						<ValidationProvider name="estado contrato" rules="required">
						<b-form-group
							label="Estado del contrato*"
							slot-scope="{ valid, errors }"
						>
							<b-form-select
							:state="errors[0] ? false : valid ? true : newContract.statusSelected != '' ? true : false"
							v-model="newContract.statusSelected"
							disabled
							>
							<option value="">Seleccione Estatus Contrato</option>
							<option
								v-for="status in contractStatus"
								:key="status.id"
								:value="status.id"
							>
								{{ status.name }}
							</option>
							</b-form-select>
							<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="3">
						<ValidationProvider rules="required" name="Tipo de pago">
						<b-form-group label="Tipo de pago*" slot-scope="{ valid, errors }">
							<b-form-select
							:state="errors[0] ? false : valid ? true : newContract.paymentType != '' ? true : false"
							v-model="newContract.paymentType"
							>
							<option value="">Tipo de pago</option>
							<option v-for="pt in paymentTypes" :key="pt.id" :value="pt.id">
								{{ pt.name }}
							</option>
							</b-form-select>
							<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="3">
						<ValidationProvider rules="required" name="Tipo de retorno">
						<b-form-group label="Tipo de retorno*" slot-scope="{ valid, errors }" >
							<b-form-select
							:state="errors[0] ? false : valid ? true : newContract.returnType != '' ? true : false"
							v-model="newContract.returnType"
							>
							<option value="">Tipo de retorno</option>
							<option v-for="ret in retornos" :key="ret.id" :value="ret.id">
								{{ ret.name }}
							</option>
							</b-form-select>
							<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="3">
						<ValidationProvider rules="required" name="Duracion">
						<b-form-group label="Duracion*" slot-scope="{ valid, errors }">
							<b-form-input
							class="form-control"
							type="number"
							v-model="newContract.duration"
							:state="errors[0] ? false : valid ? true : newContract.duration != '' ? true : false"
							@keypress="onlyNumber"
							@input="addYears"
							>
							</b-form-input>
							<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="3">
						<ValidationProvider rules="required" name="fecha inicio">
						<b-form-group label="Fecha Inicio*" slot-scope="{ valid, errors }">
							<b-form-input
							class="form-control"
							type="date"
							v-model="newContract.dateIn"
							:min="minDateContractCreate"
							@input="addEndDateDuration()"
							:state="errors[0] ? false : valid ? true : newContract.dateIn != '' ? true : false"
							></b-form-input>
							<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
						</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="3">
						<ValidationProvider rules="required" name="fecha fin">
							<b-form-group label="Fecha fin*" slot-scope="{ valid, errors }">
								<b-form-input
									class="form-control"
									type="date"
									v-model="newContract.dateOut"
									:min="newContract.dateIn"
									:max="maxDateOut"
									@input="addEndDateDuration()"
									:state="errors[0] ? false : valid ? true : newContract.dateOut != '' ? true : false"
								></b-form-input>
								<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="6">
						<ValidationProvider rules="" name="Notas">
							<b-form-group :label="charactersLeft" slot-scope="{ valid, errors }">
								<b-form-textarea
									class="form-control"
									v-model="newContract.notes"
									:state="errors[0] ? false : valid ? true : null"
								>
								</b-form-textarea>
								<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
							</b-form-group>
						</ValidationProvider>
					</b-col>
					<b-col md="">
						<b-row>
							<b-col>
								<ValidationProvider rules="" name="temporal">
									<b-form-group label="Es temporal*">
										<b-form-checkbox
											v-model="newContract.isTemporal"
											switch
											@change="setIsTemporalNewContract(newContract.isTemporal)"
										/>
									</b-form-group>
								</ValidationProvider>
							</b-col>
							<b-col>
															
								<ValidationProvider rules="" name="en venta">
									<b-form-group label="Propiedad en Venta">
										<b-form-checkbox
											v-model="newContract.isResale"
											switch
											@change="setOnSaleNewContract(newContract.isResale)"
										/>
									</b-form-group>
								</ValidationProvider>
							</b-col>
						</b-row>
					</b-col>
					<b-col md="3" v-if="newContract.isResale">
						<ValidationProvider :rules="newContract.isResale ? 'required' : ''" name="Valor de reventa">
							<b-form-group label="Valor de Reventa" slot-scope="{ valid, errors }">
								<b-input-group prepend="$">
									<b-form-input
										class="form-control"
										type="text"
										@keypress="onlyNumberWithDecimal"
										v-model="newContract.priceResale"
										:state="errors[0] ? false : valid ? true : null"
									/>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-input-group>
							</b-form-group>
						</ValidationProvider>
					</b-col>
				</b-row>

				<b-row class="mt-1 border-benfits-membership rounded  mb-1">
					<b-col md="12">
						<app-collapse v-if="!isSearchingBenefits">
							<app-collapse-item title="Beneficios de la membresía seleccionada" :isVisible="true">
								<b-input v-if="newContract.benefitsMembership.length > 6" v-model="filterBenefits" placeholder="Busqueda por nombre en la tabla" class="mb-2"></b-input>
								<div class="table-responsive tbodyDiv" v-if="newContract.benefitsMembership.length">
									<table class="table">
										<thead class="sticky-top">
											<tr>
											<th scope="col">Nombre</th>
											<th scope="col">Cantidad</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(benefit) in filteredBenefitsMembership" :key="benefit.benefit">
											<th scope="row">{{benefit.name}}</th>
											<td>
												<b-form-input class="form-control input-size-sm" disabled type="number" step="any" v-model="benefit.qty" @keypress="onlyNumber"></b-form-input>
											</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-else>
									<b-alert show variant="danger" >
										<div class="alert-body">
											<feather-icon class="mr-25" icon="InfoIcon" />
											<span class="ml-25">Esta membresía no tiene beneficios o seleccione otra que sí tenga.</span>
										</div>
									</b-alert>
								</div>
								<!-- filteredBenefitsMembership -->
							</app-collapse-item>
						</app-collapse>
						<div class="text-center mt-2" v-if="isSearchingBenefits">
							<b-spinner style="width: 3rem; height: 3rem;" /><br>
							<strong>Cargando beneficios de la membresía del contrato</strong>
						</div>
					</b-col>
				</b-row>
		

				<b-row class="mt-1 border-extra-benfits-membership rounded  mb-1">
					<b-col md="12">
						<app-collapse v-if="!isSearchingBenefits">
							<app-collapse-item title="Beneficios extra" :isVisible="true">
								<b-input v-if="newContract.extraBenefitsMembership.length > 6" v-model="filterExtraBenefits" placeholder="Busqueda por nombre en la tabla" class="mb-2"></b-input>
								<div class="table-responsive tbodyDiv" v-if="newContract.extraBenefitsMembership.length">
								<table class="table">
									<thead class="sticky-top">
										<tr>
											<th scope="col">Nombre</th>
											<th scope="col">Inicio</th>
											<th scope="col">Fin</th>
											<th scope="col">Cantidad</th>
											<!-- <th scope="col">Monto</th> -->
											<th scope="col">Notas Internas</th>
											<th scope="col">Traspaso de Saldo</th>
											
											<th scope="col">Añadir</th>
											<th scope="col">Reset</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(benefit) in filteredExtraBenefitsMembership" :key="benefit.benefit" :class="benefit.isSelected ? 'table-success' : '' ">
											<th scope="row">{{benefit.name}}</th>
											<td>
												<b-form-group>
													<b-form-input
													class="form-control"
													type="date"
													v-model="benefit.dateIn"
													:min="newContract.dateIn"
													:max="newContract.dateOut"
													></b-form-input>
												</b-form-group>
											</td>
											<td>
											<b-form-group>
												<b-form-input
												class="form-control"
												type="date"
												v-model="benefit.dateOut"
												:min="newContract.dateIn"
												:max="newContract.dateOut"
												></b-form-input>
											</b-form-group>
											</td>
											<td>
											<b-form-input
												class="form-control input-size-sm"
												type="number"
												v-model="benefit.qty"
												step="any"
												@input="setPartialDataExtraBenefit( benefit )"
											>
											<!-- :disabled="benefit.qty === 0 || benefit.amount !== '' " -->
											</b-form-input>
											</td>
											<td>
												<b-form-input
													class="form-control input-size-sm"
													type="text"
													v-model="benefit.notes"
													@input="setPartialDataExtraBenefit( benefit )"
												></b-form-input>
											</td>
											<td>
												<b-form-group>
													<b-form-checkbox
														v-model="benefit.isNew"
														switch
													/>
												</b-form-group>
											</td>
											<td>
											<b-button variant="primary" size="sm" @click="completeDataExtraBenefit(benefit)" >
												<feather-icon size="16" :icon="benefit.isSelected ? 'CheckCircleIcon' : 'PlusIcon'" class="mr-40" />
											</b-button>
											</td>
											<td>
											<b-button variant="warning" size="sm" @click="clearExtraBenefit(benefit)" >
												<feather-icon size="16" icon="RefreshCcwIcon" class="mr-40" />
											</b-button>
											</td>
										</tr>
									
									</tbody>
								</table>
							</div>
							<div v-else>
								<b-alert show variant="danger" >
									<div class="alert-body">
										<feather-icon class="mr-25" icon="InfoIcon" />
										<span class="ml-25">No se tiene beneficios extra a mostrar.</span>
									</div>
								</b-alert>
							</div>
								<!-- filteredBenefitsMembership -->
							</app-collapse-item>
						</app-collapse>
						<div class="text-center mt-2" v-if="isSearchingBenefits">
							<b-spinner style="width: 3rem; height: 3rem;" /><br>
							<strong>Cargando beneficios de la membresía del contrato</strong>
						</div>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<div class="d-flex justify-content-end">
							<b-button  variant="warning" class="mr-2" @click="resetFormNewContrac">  Reset</b-button>
							<b-button
								type="submit"
								variant="primary"
								v-can="'fivesclub_contracts_show_add_save'"
								:disabled="invalid || isSavingContract || newContract.ownerSelected ==='' "
							>
								<b-spinner small v-if="isSavingContract" /> Guardar
							</b-button>
						</div>
					</b-col>
				</b-row>

			</b-form>
		</ValidationObserver>
	</b-overlay>
</template>

<script>
import * as moment from 'moment'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { mapActions, mapState, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import { showAlertMessage, currentDate, formatDateSpecific } from '@/helpers/helpers'
import { makeParamsFilterContracts } from '@/helpers/fivesClubHelper'


export default {
	mixins: [utils],
	components: {
		vSelect,
		AppCollapse,
		AppCollapseItem,
	},
	data() {
		return {
			filterBenefits: '',
			filterExtraBenefits: '',
			//loader
			isSavingContract: false,
			isSearchingOwners: false,
			isSearchingBenefits: false,
			fechaActual: currentDate(),
			max: 255,
			showRedirectLabel: false,
			contractToEdit: null,
			contractIdToEdit: null,

			validationPreviousContractData: {
				oldhotel: null,
				oldnumberHouse: null,
				oldownerSelected: null,
				oldselectedeMembership: null,
			}
		}
	},
  
	mounted(){
		this.setInitialValues()
		this.resetFormNewContrac()
	},
	computed: {
		...mapState('fivesClubCatalogs', ['memberships','typeMemberships', 'paymentTypes', 'retornos', 'blackouts', 'benefits' ]),
		...mapState('fivesClubContracts', ['newContract','contractstatus','minDateContractCreate']),
		...mapState('start', ['hotels', 'currencies']),
		...mapState('auth', ['user']),
		charactersLeft(){      
			return `Escriba notas: le queda ${this.max - this.newContract.notes.length } carácteres.`        
		},
		ownersList(){
			return {
				owners : this.newContract.owners,
			}
		},
		contractStatus(){
			return this.contractstatus
		},
		filteredBenefitsMembership() {
			return this.newContract.benefitsMembership.filter( row => {
				const nameBenefit = row.name.toString().toLowerCase()
				const searchTerm = this.filterBenefits.toLowerCase()
				return nameBenefit.includes(searchTerm)
			})
		},
		filteredExtraBenefitsMembership() {
			return this.newContract.extraBenefitsMembership.filter( row => {
				const nameBenefit = row.name.toString().toLowerCase()
				const searchTerm = this.filterExtraBenefits.toLowerCase()
				return nameBenefit.includes(searchTerm)
			})
		},
		maxDateOut(){
			let date = null

			if (this.newContract.duration != null && this.newContract.duration != '') {
				const dateInit = moment(this.newContract.dateIn)
				const dateEnd = dateInit.add(parseInt(this.newContract.duration), 'year')

				const dateInstance = moment(dateEnd)
				date = dateInstance.format('YYYY-MM-DD')

				if (moment(this.newContract.dateOut).isAfter(dateInstance) ) {
					this.newContract.dateOut = structuredClone(date)
				}
			}

			return date
		}
	},
	methods: {
		...mapActions('fivesClubCatalogs', ['getHousingOwners']),
		...mapActions('fivesClubContracts', ['saveNewContract', 'fetchMemberships', 'fetchBenefitsXtra','fetchContracts','fetchPreviousCanceledContract']),
		...mapMutations('fivesClubContracts', ['setDataHousingNewContract','resetDataHousingNewContract','resetOwnerSelectedNewContract','setBenefitsNewContract','setExtraBenefitsNewContract','completeDataExtraBenefitInNewContract','resetDataExtraBenefitInNewContract','resetDataInNewContract','setSelectedContract','addContract','setDataDurationNewContract','resetExtraBenefitsNewContract','setDatesExtraBenefitsNewContract','setDatesNewContract','setIsTemporalNewContract', 'setOnSaleNewContract','setMinDateContract']),
		async getOwners(){
			this.isSearchingOwners = true
			this.showRedirectLabel = false
			this.contractToEdit = null
			this.contractIdToEdit = null
			this.setMinDateContract(null)
			this.resetCancelContractValues()
			const { hotel, numberHouse } = this.newContract
			const payload = { idHotel: hotel, numberHouse:parseFloat(numberHouse), createContract: true }
			const { status, data, message } = await this.getHousingOwners(payload)
			if(status) this.setDataHousingNewContract(data)
			if(!status){
				this.resetHouseSection()
				this.resetOwnerSelectedNewContract()
				showAlertMessage('Respuesta búsqueda', 'BellIcon', message, 'danger', 3000, 'bottom-right')
				if (data.requiresEndDate) {
					this.showRedirectLabel = true
					this.contractToEdit = data.contractCode
					this.contractIdToEdit = data.contractId
				}
			}
			this.verifyPreviousContract()
			this.isSearchingOwners = false
		},
		addYears( duration ){
			this.resetExtraBenefitsNewContract()
			const dateIn = !!this.minDateContractCreate ? moment(this.minDateContractCreate) : moment()
			if( duration && duration > 0 ){
				const dateOut = !!this.minDateContractCreate ? moment(this.minDateContractCreate) : moment()
				dateOut.add(parseInt(duration), 'years')
				const payloadDuration = {
					duration: parseFloat(duration),
					dateIn: formatDateSpecific({date: dateIn, toFormat:'YYYY-MM-DD'}),
					dateOut: formatDateSpecific({date: dateOut, toFormat:'YYYY-MM-DD'}),
				}
				this.setDataDurationNewContract(payloadDuration) 
				if(this.newContract.extraBenefitsMembership.length > 0 ){
					this.isSearchingBenefits = true
					this.setDatesExtraBenefitsNewContract(payloadDuration)   
					this.isSearchingBenefits = false
				}
			} else {
				showAlertMessage('Duración invalida', 'BellIcon', '¡No dejes este campo vacío o en cero!', 'danger', 3000, 'bottom-right')
				this.setDataDurationNewContract({duration:'', dateIn: '', dateOut: ''})
			}
		},
		addEndDateDuration(){
			const { duration,dateIn,dateOut } = this.newContract
			if(duration != null && duration != '' ){
				this.setDatesNewContract({dateIn, dateOut})
				this.setDatesExtraBenefitsNewContract({dateIn, dateOut})
			}
		},
		resetHouseSection(){
			this.resetDataHousingNewContract()
			this.$refs.searchOwner.reset() 
		},
		async setBenefitsForMembershipSelected(idMembership){
		
			this.isSearchingBenefits = true
			if(!idMembership){
				this.isSearchingBenefits = false
				this.setBenefitsNewContract([])
				this.setExtraBenefitsNewContract([])
				return
			}
			let benefits = []
			const membership = this.memberships.find( membership => membership.id === idMembership )
			const { Benefits } = membership
			Benefits.forEach( benefit => {
				benefits.push({
					benefit: benefit.idBenfit, name: benefit.nameBenefit, idUser: this.user.idUser,
					dateIn: "", dateOut: "", qty: benefit.can, isExtra: false,
				})
			})      
			this.setBenefitsNewContract(benefits)
			const extraBenefits = await this.getExtraBenefits(idMembership) //pido los extra benefits
			this.setExtraBenefitsNewContract(extraBenefits) // muto state
			if (this.newContract.duration && this.newContract.duration !== '') this.addYears(this.newContract.duration)
			this.verifyPreviousContract()

			this.isSearchingBenefits = false
		},
		async getExtraBenefits(idMembership){
			let extraBenefits = []
			const extras = await this.fetchBenefitsXtra(idMembership) 
			extras.forEach(benefit => {
				extraBenefits.push({
					benefit: benefit.id, name: benefit.name, dateIn: "", dateOut: "", qty: "",
					isExtra: true, idUser: this.user.idUser, isSelected: false,
				})
			})
			return extraBenefits
		},
		setPartialDataExtraBenefit( extraBenefit ){
			this.isSearchingBenefits = true
			const { dateIn, dateOut, qty, benefit, notes } = extraBenefit
			const payload = { qty, isSelected: false, benefit, dateIn, dateOut, notes }
			this.completeDataExtraBenefitInNewContract(payload)
			this.isSearchingBenefits = false
		},
		completeDataExtraBenefit(extraBenefit){
			this.isSearchingBenefits = true
			const { dateIn, dateOut, qty, benefit, notes } = extraBenefit

			if(this.newContract.duration ==='' || this.newContract.duration === 0){
				showAlertMessage('Duración de contrato', 'BellIcon', '¡Tiene que indicar la duración del contrato primero!', 'danger', 3000, 'bottom-right')        
				extraBenefit.isSelected = false
				this.isSearchingBenefits = false   
				return
			}
			//validar si por lo menos qty o amount tiene valor
			const isEmptyAmounts = [qty].includes('')
			if(isEmptyAmounts){
				showAlertMessage('Falta cantidad', 'BellIcon', '¡Tiene que indicar una cantidad!', 'danger', 3000, 'bottom-right')        
				extraBenefit.isSelected = false
				this.isSearchingBenefits = false
				return
			}
			const isEmptyDates = [dateIn].includes('') && [dateOut].includes('')
			if(isEmptyDates){
				showAlertMessage('Falta fechas', 'BellIcon', '¡Tiene que indicar las fechas', 'danger', 3000, 'bottom-right')        
				extraBenefit.isSelected = false
				this.isSearchingBenefits = false
				return
			}

			const isValidDates = moment(dateOut).isSameOrAfter(dateIn, 'day')
			if(!isValidDates){
				showAlertMessage('Fechas inválidas', 'BellIcon', '¡Fecha fin no debe ser menor a fecha de inicio', 'danger', 3000, 'bottom-right')        
				extraBenefit.isSelected = false
				this.isSearchingBenefits = false
				return
			}
			const payload = {
				qty: qty !== '' ? parseFloat(qty) : 0,
				isSelected: true,
				benefit,
				dateIn,
				dateOut,
				notes
			}
			//muto
			this.completeDataExtraBenefitInNewContract(payload)
			this.isSearchingBenefits = false
		},
		clearExtraBenefit(extraBenefit){
			const { benefit } = extraBenefit
			this.resetDataExtraBenefitInNewContract({benefit})
		},
		async verifyPreviousContract(){
			const {hotel, numberHouse, ownerSelected, selectedeMembership, duration} = this.newContract
			const {oldhotel, oldnumberHouse, oldownerSelected, oldselectedeMembership} = this.validationPreviousContractData
			if (!!hotel && !!numberHouse && !!ownerSelected && !!selectedeMembership) {
				if ( hotel != oldhotel || numberHouse != oldnumberHouse || ownerSelected != oldownerSelected || selectedeMembership != oldselectedeMembership) {
					const response = await this.fetchPreviousCanceledContract({resort: hotel, number: numberHouse, membership: selectedeMembership, ownerId: ownerSelected})
					this.setMinDateContract(response)

					this.addYears(duration)
				}
				this.validationPreviousContractData = {
					oldhotel: hotel,
					oldnumberHouse: numberHouse,
					oldownerSelected: ownerSelected,
					oldselectedeMembership: selectedeMembership,
				}
			}
		},
		resetCancelContractValues() {
			this.validationPreviousContractData = {
				oldhotel: null,
				oldnumberHouse: null,
				oldownerSelected: null,
				oldselectedeMembership: null,
			}
			this.setMinDateContract(null)
		},
		async saveContract(){

			const {
				dateIn, dateOut, duration, statusSelected, idHousing, selectedeMembership,
				ownerSelected, paymentType, returnType, notes, benefitsMembership, extraBenefitsMembership, isTemporal, isResale, priceResale
			} = this.newContract
		
			const extraBenefits = extraBenefitsMembership.filter(extraBenefit => extraBenefit.isSelected )

			benefitsMembership.forEach( xtra => {
				xtra.isNew = false
			})
			
			extraBenefits.forEach( xtra => {
				xtra.isNew = xtra.isNew || false
			})

			const isValidDates = moment(dateOut).isSameOrAfter(dateIn, 'day')

			if(!isValidDates){
				showAlertMessage('Fechas inválidas duración', 'BellIcon', '¡Fecha fin de contrato no debe ser menor a la fecha de inicio', 'danger', 3000, 'bottom-right')               
				return
			}

			this.isSavingContract = true

			const payload = {
				code: "",
				numbercontract: "",
				datein: dateIn,
				dateout: dateOut,
				duration: parseInt(duration),
				status: statusSelected,
				idHousing,
				idMembership: selectedeMembership,
				idOwner: ownerSelected,
				idPayment: paymentType,
				idReturn: returnType,
				isTemporal,
				isResale,
				priceResale: isResale ? priceResale: 0,
				currencyId: 1,
				note: notes,
				idUser: this.user.idUser,
				benefitXtra:[...benefitsMembership, ...extraBenefits ],
			}

			const respuesta = await this.saveNewContract(payload)      
			const { status, data } = respuesta
			const newContract = makeParamsFilterContracts({ typeFilter: 'idContract', idContract: data.idContract })

			if( status ){
				Object.assign( this.$data, this.$options.data() ) 
				this.$refs.createContract.reset() //reseteo form
				showAlertMessage('Contract Saved', 'BellIcon', 'Contract has been created!', 'success', 4000, 'bottom-right')
				this.resetDataInNewContract()
				const contracts = await this.fetchContracts(newContract)//obtener el nuevo contract
				const nuevoContrato = contracts.length > 0 ? contracts[0] : null
				if( nuevoContrato ){
					this.setMinDateContract(null)
					this.addContract(nuevoContrato) //lo añado al state de mi lista de contratos
					this.setSelectedContract(nuevoContrato) //actualizo el state para tener contrato a editar
					this.$router.push({ name: 'edit-contract', params: { idContract: nuevoContrato.id } }) // lo mando a vista de edición
				}
			}
			this.isSavingContract = false
		},
		resetFormNewContrac(){
			this.resetDataInNewContract() //pone valores por defecto, duration en 1 por ejemplo
			this.$refs.searchOwner.reset() //reseteo form
			if(this.newContract.owners.length){
				this.$refs.createContract.reset() //reseteo form
			}
		},
		setInitialValues(){
			this.newContract.statusSelected = 1
			this.newContract.ownerSelected = ""
			this.newContract.selectedeMembership = ""
		},
		async goEditContract(){
			const idContract = structuredClone(this.contractIdToEdit)
			this.showRedirectLabel = false
			this.contractToEdit = null
			this.contractIdToEdit = null
            const contract = await this.fetchContracts({IdContract: idContract})
			this.setSelectedContract(contract[0])

			this.$router.push({ name: 'edit-contract', params: { idContract } })
		}
	},
	watch: {
		ownersList: {
			deep: true,
			handler() {
				this.resetOwnerSelectedNewContract()
			},
		},
	},
}
</script>
<style lang="scss" scoped>

	@import "@core/scss/vue/libs/vue-select.scss";
	.border-benfits-membership{
		border: 2px solid #333366;
	}
	.border-extra-benfits-membership{
		border: 2px solid #ff9f43;
	}
	.tbodyDiv {
		max-height: 30rem;
		overflow: auto;
	}
</style>