var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"sm","id":'modal-status' + _vm.contract.id,"title":'Contrato: ' + _vm.contract.code,"ok-only":"","hide-footer":"","no-close-on-backdrop":""}},[_c('h4',[_vm._v("Estado del contrato")]),_c('ValidationObserver',{ref:"uploadFilesForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateStatus($event)}}},[_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"tipo de archivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Seleccione una opción"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.actualcontractstatus),callback:function ($$v) {_vm.actualcontractstatus=$$v},expression:"actualcontractstatus"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.showStatusContract),function(status){return _c('option',{key:status.id,domProps:{"value":status.id}},[_vm._v(" "+_vm._s(status.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),(_vm.actualcontractstatus == 3)?_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Término del Contrato"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Término del Contrato"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"date","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.selectedContractEndDate),callback:function ($$v) {_vm.selectedContractEndDate=$$v},expression:"selectedContractEndDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1):_vm._e(),(_vm.actualcontractstatus == 3)?_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"nota","rules":_vm.handlerStatus(_vm.actualcontractstatus)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota"}},[_c('b-form-textarea',{attrs:{"placeholder":"Motivo de cancelación","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.textCancelation),callback:function ($$v) {_vm.textCancelation=$$v},expression:"textCancelation"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.isUpdating}},[_vm._v(" Actualizar ")])],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }