<template>
  <div style="overflow-x:auto;">
    <b-table
      :filter="filter.queryContracts"
      :items="contracts"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="isSortBy"
      show-empty
      empty-text="No se encontraron contratos"
      :sort-desc.sync="isSortDir"
      class="position-relative mb-0 minHTable"
      v-if="contracts"
    >

      <template #cell(code)="row">
        <div class="text-nowrap">
          <b>Código: </b>{{row.item.code}} <br>
          <!-- <b>Código de vivienda: </b>{{row.item.housingcode}} <br> -->
          <b>Pool de Renta: </b>{{row.item.rentalPool || 'N/A'}} <br>
          <b>Membresía: </b><b-badge variant="primary">{{row.item.membershipcode}} </b-badge><br>
          <b>Tipo de pago: </b>{{row.item.paymenttypename}}<br>
          <b>Tipo de retorno: </b>{{ row.item.returntypename }}
        </div>
      </template>

      <template #cell(actions)="row" v-if="can('fivesclub_contracts_show_contracts_action_button')">
        <div class="btn-actions">
          <b-dropdown
            variant="link"
            toggle-class="p-0 mr-1"
            text="Form"
            no-caret
            right
          >
            <template #button-content>
              <div class="docs-btn">
                <b-button variant="primary" size="sm">
                  <feather-icon
                    icon="FileTextIcon"
                    size="16"
                    class="align-middle"
                  /> <b class="docs-btn-number">{{row.item.documents.length}} </b>
                </b-button>
              </div>
            </template>

            <div v-if="row.item.documents.length > 0">
              <div class="p-1"> <b>Documentos en el contrato</b></div>
              <div class="content-detail">
                <b-dropdown-item  v-for="(doc, index) in row.item.documents" :key="index"  @click="goToDocuments(row.item)">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">{{ doc }}</span>
                </b-dropdown-item>
              </div>
            </div>

            <div v-else>
              <p class="m-1 text-danger">Esté contrato aún no tiene documentos</p>
            </div>
          </b-dropdown>

          <b-dropdown toggle-class="p-0" no-caret right v-if="can('fivesclub_contracts_show_contracts_action_button')">
            <template #button-content>
              <b-button variant="primary" size="sm">
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                  style="color: white"
                />
              </b-button>
            </template>

            <b-dropdown-item @click="setContractToEdit(row.item)" v-can="'fivesclub_contracts_show_contracts_action_button_edit'">
              <feather-icon :icon="row.item.statuscontractname === 'CANCELADO' ? 'EyeIcon' : 'EditIcon'" />
              <span class="align-middle ml-50"> Beneficios y condiciones</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'contractsInfo', params: { contracts: 'contracts-' + row.item.id }, }" >
              <feather-icon icon="DollarSignIcon" />
              <span class="align-middle ml-50">Ver Saldo de beneficios</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showModalOwners( row.item )">
              <feather-icon icon="UsersIcon" />
              <span class="align-middle ml-50">Ver propietarios</span>
              <ModalOwners :contract="row.item" :owners="owners" />
            </b-dropdown-item>

            <b-dropdown-item  @click="goToDocuments(row.item)" v-can="'fivesclub_contracts_show_contracts_action_button_files'">
              <feather-icon icon="FilePlusIcon" />
              <span class="align-middle ml-50">Documentos</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showModalStatus( row.item )" v-if="(row.item.statusContract != 3)" v-can="'fivesclub_contracts_show_contracts_action_button_show_status'">
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">Status del Contrato</span>
              <ModalStatus :contract="row.item"/>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #cell(dateStart)="row">
        <div class="text-nowrap">
          <b>Fecha Inicio:</b><br>{{row.item.dateStart}}<br>
          <b>Fecha Fin: </b><br>{{row.item.dateEnd }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import { showAlertMessage } from '@/helpers/helpers'

import ModalStatus from "@/modules/fivesClub/components/contracts/ModalStatus"
import ModalOwners from "@/modules/fivesClub/components/contracts/ModalOwners"

export default {
  mixins: [utils, acl],
  components: {
    ModalStatus,
    ModalOwners
  },
  props: {
    contracts: {
      type: Array,
      required: false,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
      owners: []
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState('fivesClubCatalogs', ['filter']),
  },

  methods: {
    ...mapMutations('fivesClubContracts', ['setSelectedContract','setContractsBenefits','setContractDocs','setIsLoadingContracts']),
    ...mapActions('fivesClubContracts', ['getInitialContentContracts', 'fetchInfoOwnerOrHousing']),
    setContractToEdit(contract) {
      this.setSelectedContract(contract)
      this.$router.push({ name: 'edit-contract', params: { idContract: contract.id } })
    },
    goToDocuments(contract){
      this.setContractDocs(contract)
      this.$router.push({ name: 'docs-contract', params: { idContract: contract.id } })
    },

    async showModalOwners(data) {
      const response = await this.fetchInfoOwnerOrHousing({idContract: data.id})
      if (response?.length > 0){
        this.owners = response
        this.$root.$emit("bv::show::modal", 'modal-owners' + data.id)
      } else return showAlertMessage( 'No se encontraron propietarios', 'InfoIcon', 'El contrato no cuenta con propietarios', 'warning', 4000, 'bottom-right' )
    },
    async showModalStatus(item) {
      if (item.statusContract != 3) this.$root.$emit("bv::show::modal", 'modal-status' + item.id )
    }
  },
};
</script>

<style scoped>
.minHTable{
  min-height: 350px;
}
.btn-actions{
  display: flex;
}
.docs-btn{
  position: relative;
}
.docs-btn-number{
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1rem;
  color: white ;
  font-weight: bold;
}
.content-detail{
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.content-detail::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.content-detail::-webkit-scrollbar{
  width: 10px;
  background-color: #F5F5F5;
}

.content-detail::-webkit-scrollbar-thumb {
  background-color: #333366;
  background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

</style>