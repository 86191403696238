<template>
    <b-tabs v-model="tabIndex">
        <b-tab title="Contratos" @click="clickTab">
          <ListContracts @set-tab-index="setTabIndex"/>
        </b-tab>
        
          <b-tab @click="clickTab" >
            <template #title >
              <div v-can="'fivesclub_contracts_show_add'">
                Crear
              </div>
            </template>
            <ContractCreate @set-tab-index="setTabIndex" v-can="'fivesclub_contracts_show_add'"/>
          </b-tab>        
    </b-tabs> 
</template>

<script>
import { mapState, mapMutations  } from 'vuex'
import ListContracts from '@/modules/fivesClub/components/contracts/ListContracts'
import ContractCreate from '@/modules/fivesClub/components/contracts/ContractCreate'

export default {
  components:{
    ListContracts,
    ContractCreate    
  },
  created() {
    
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  computed: {
    ...mapState('fivesClubContracts',['selectedContract','contractDocuments']),
  },
  methods: {
    ...mapMutations('fivesClubContracts',['setSelectedContract',]),
    setTabIndex( index ){      
      this.tabIndex = index    
    },
    
    clickTab(){      
      this.setSelectedContract(null) 
    },
    clickTabResetEdit(index){
      this.tabIndex = index    
      this.clickTab()
    },
    

  },
};
</script>
