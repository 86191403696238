<template>
    <div>
        <Filters
            :perPage="perPage"
            :perPageOptions="perPageOptions"
            :isLoadingContracts="isLoadingContracts"
            @change-per-page="changePerPage"
            @look-per-filter="getContracts"
        />
        <div v-if="contracts.length">
            <TableContracts
                :contracts="contractData"
                :tableColumns="tableColumns"
                :sortBy="sortBy"
                :isSortDirDesc="isSortDirDesc"
                @set-tab-index="setTabIndex"
            />
            <PaginationTable
                :currentPage="currentPage"
                :totalRows="filteredContracts.length"
                :perPage="perPage"
                @change-page="changePage"
                v-if="filteredContracts.length > 10"
            />
        </div>
        <div v-if="!contracts.length && !isLoadingContracts">
            <b-alert
                variant="warning"
                :show="true"
                class="mb-0"
                >
                <div class="alert-body">
                    <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                    />
                    Sin contratos, haga una busqueda mediante el filtro
                </div>
            </b-alert>
        </div>
        <div class="isCentered isSpinner" v-if="isLoadingContracts">
            <center>
                <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
                Cargando información
            </center>
        </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters,  } from 'vuex'
import Filters from '@/modules/fivesClub/components/contracts/Filters'
import TableContracts from '@/modules/fivesClub/components/contracts/TableContracts'
import PaginationTable from '@/modules/fivesClub/components/contracts/PaginationTable'
import { nameDocuments } from '@/data/fivesClub'


export default {
    components:{
        TableContracts,
        PaginationTable,
        Filters
    },
    data(){
        return {
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20, 25, 50, 100],
            searchQuery: '',
            sortBy: 'id',
            isSortDirDesc: false,
            statusFilter: null,
            nameDocuments,
            tableColumns: [
                { key: 'code', label: 'Información' },
                { key: 'resortsname', label: 'Resort'},

                // { key: 'housingcode', label: 'Código de Vivienda', sortable: true, },
                { key: 'housingnumber', label: 'N° Vivienda', sortable: true, },
                { key: 'nameOwner', label: 'Owner', sortable: true,},
                // { key: 'membershipcode', label: 'Membresía' },
                { key: 'dateStart', label: 'Vigencia', sortable: true, },
                { key: 'endDate', label: 'Fecha de Finalización', sortable: true, formatter: value => {
                    return value || 'N/A'
                } },
                // { key: 'paymenttypename', label: 'Tipo de pago' },
                // { key: 'returntypename', label: 'Tipo de retorno' },
                { key: 'statuscontractname', label: 'Estado del Contrato' },

                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
        }
    },
    computed: {
        ...mapState('fivesClubContracts',['contracts','isLoadingContracts']),
        ...mapGetters('fivesClubContracts', ['filteredContracts']),
        ...mapState('auth', ['user']),
        contractData(){
            if (this.filteredContracts?.length) return this.filteredContracts?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
        },
    },
    methods:{
        ...mapActions('fivesClubContracts', ['fetchContracts', 'fetchMemberships']),
        ...mapMutations('fivesClubContracts',['setSelectedContract', 'setContracts','setRequestContracts','setIsLoadingContracts']),

        changePage(page){
            this.currentPage = page
        },
        changePerPage(perPage){
            this.perPage = perPage
        },

        async getContracts(payload){
            // console.log(payload)
            this.setContracts( [] )
            this.setRequestContracts(payload)
            this.setIsLoadingContracts(true)
            const contracts = await this.fetchContracts(payload)
            // contracts.forEach(contract => {
            //     contract.documents = nameDocuments
            // })
            this.setContracts( contracts )
            this.setIsLoadingContracts(false)
            console.log('getContracts')

        },
        setTabIndex(index){
            this.$emit('set-tab-index', index)
        },

    }
}
</script>
<style scoped>
.isCentered{
    align-content: center;
    align-items: center;
}
.isSpinner{
    padding: 2rem;
}
</style>